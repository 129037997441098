import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react'
import Colors from '../constants/Colors'
import AnimMessenger from './AnimMessenger'
import Menu from './Menu'
import AppLogo from './AppLogo'
import Layout from '../constants/Layout'

class PageHeader extends Component {
    

    render() {
        const {isSmallDevice, isTablet} = Layout
        console.log('isSmallDevice', isSmallDevice)
        return ( 
            <div className="headsegment">
                <AppLogo classAttribute="bounce-in-top" size={isSmallDevice ? "small" : "medium"}/>
                {
                    (isSmallDevice || isTablet)
                    ? <div className="lobstertxt" style={{alignSelf: 'center', fontSize: isTablet ? '1.7em' : '1.2em', minHeight: isTablet ? "14em" : "9em", marginTop: '9em'}}>
                        Depuis 2009, nous réalisons vos travaux
                        <div><AnimMessenger messengerClass="lobstertxt" messengerStyle={{alignSelf: 'center', fontSize: '1.5em'}} /></div>
                    </div>
                    : <Grid style={{color:Colors.anthracite, marginTop: '5em', fontSize: '2.5em', minHeight:"8em"}} className="lobstertxt">
                        <Grid.Row columns={2}>
                            <Grid.Column className='right aligned ten wide column lobstertxt' style={{marginRight:'-1.5%'}}>
                                Depuis 2009, nous réalisons vos travaux 
                            </Grid.Column>
                            <Grid.Column className='left aligned six wide column'>
                                <AnimMessenger messengerClass="lobstertxt" />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
                <Menu />

                
            </div>
        );
    }
}

export default PageHeader; 