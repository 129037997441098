import React, {Component} from 'react';
import { Divider, Button, Image, Icon, Segment } from 'semantic-ui-react'
import Layout from '../constants/Layout'
import Head from './Head';

class ContactUs extends Component {
    // dom elements referencement
    constructor() {
        super()
        this.state = {
            hasBeenSubmited : false,
            hasBeenSent : false,
            // mail datas from inputs
            core : null, 
            subject : null,
            tel : null,
            from : null,
            sendError: false
        }
    }  

    sendMail = async() => {
        // configure mailer
        //const { from, subject, message, tel } = this.state
        try {
       
            fetch(
                'https://amcleblancserver.herokuapp.com/mailing',
                {
                    method:'POST',
                    mode:'cors',
                    headers: {
                        'Accept': '*',
                        'Content-Type': 'application/json' 
                    },
                    //body:{...this.state, message: message+'<br />N° Téléphone: '+(tel || 'n/c'), returnTextResponse:true, to:['cie'])
                    body : JSON.stringify({...this.state, returnTextResponse:true, to:'cie'})
                }   
            ).then((mailSent) => {
                //console.log('mailSent', mailSent)
                console.log('mail sent status', mailSent.status)
                console.log('mail sent response', mailSent.statusText)
                mailSent.text().then((responseText) => {
                    console.log('mailSent responseText', responseText)
                    this.setState({hasBeenSent: mailSent.status === 200, sendError:false})
                })


            }, (err) => {
                console.log('error', err)
            })
           
        } catch(err) {
            console.error('Send Mail Error: '+err)
            this.setState({sendError:err})
        }
    }

    // DEPRECATED OLD SERVER VERSION
    // sendMail = async() => {
    //     // configure mailer
    //     //const { from, subject, message, tel } = this.state
    //     try {
       
    //         fetch(
    //             'https://amcleblanc.herokuapp.com/mailing',
    //             {
    //                 method:'POST',
    //                 mode:'cors',
    //                 headers: {
    //                     'Accept': '*',
    //                     'Content-Type': 'application/json' 
    //                 },
    //                 //body:{...this.state, message: message+'<br />N° Téléphone: '+(tel || 'n/c'), returnTextResponse:true, to:['cie'])
    //                 body : JSON.stringify({...this.state, returnTextResponse:true, to:'cie'})
    //             }   
    //         ).then((mailSent) => {
    //             //console.log('mailSent', mailSent)
    //             console.log('mail sent status', mailSent.status)
    //             console.log('mail sent response', mailSent.statusText)
    //             mailSent.text().then((responseText) => {
    //                 this.setState({hasBeenSent: (mailSent.status === 200 && responseText === 'message sent'), sendError:false})

    //             })


    //         }, (err) => {
    //             console.log('error', err)
    //         })

            
           
    //     } catch(err) {
    //         console.error('Send Mail Error: '+err)
    //         this.setState({sendError:err})
    //     }
        
          

    // }
    
    /* Check if an email has been sent or needs to be send */
    componentDidUpdate() {
        const { hasBeenSubmited, hasBeenSent, sendError } = this.state

        if (hasBeenSubmited && !hasBeenSent && !sendError) {
            this.sendMail()
        } 

    }

    /* Responsible for setting the component state each time an input is changed */
    composeMail = (e) => {
        let $e = e.target
        let key = $e.getAttribute('data-value')
        let keyValue = $e.value
        this.setState({[key]:keyValue})
    }

    /* responsible for displaying either the 'sent message' if message has been submitted or the message form */
    maybeMessageSent = () => {
        const { hasBeenSent, sendError, from, tel, core, subject } = this.state
        const {isSmallDevice} = Layout
        const inpClass = isSmallDevice ? " midWidth" : ""
        return(
            (!hasBeenSent && !sendError)
            ? <form id="contactForm" /*method="post" action="https://amcleblanc.herokuapp.com/mailing"*/>
                <div className="ui grid">
                    <div className="ten wide column">
                        <input onChange={this.composeMail} data-value="from" className={`ui rounded large input${inpClass}`} name="from" type="text" placeholder=" votre adresse email" value={from || ''}/>
                        <input onChange={this.composeMail} data-value="tel" className={`ui rounded large input${inpClass}`} name="tel" type="text" placeholder=" votre contact téléphonique" value={tel || ''}/>
                        <input name="to" type="hidden" value="cie" />
                        <input name="returnTextResponse" type="hidden" value="true" />
                        <input onChange={this.composeMail} data-value="subject" className={`ui rounded large input${inpClass}`} name="subject" type="text" placeholder=" sujet de votre message" value={subject || ''}/>
                    </div>
                    <div className="six wide column flexcenter" style={{flex:1}}>
                        <div className="centered hovblue grey white tekotxt" style={{fontSize:isSmallDevice ? '1em' : '25px'}}>Délais de réponse : <br /><span className="ui small header amcred titled">24H ouvrés</span> <br /> maximum</div>
                    </div>
                </div>
                <Divider className="light-padding" />
                <textarea onChange={this.composeMail} data-value="core" className="ui rounded allwidth light-padding input" name="core" id="ctcdim_3" rows="10" placeholder=" votre message" value={core || ''} style={isSmallDevice ? {height: '20vh'} : undefined}></textarea>
                <Button onClick={(e) => { e.preventDefault(); this.setState({hasBeenSubmited:true}) }} style={{marginTop:'.5em'}} inverted animated>
                    <Button.Content visible>
                        <span className="tekotxt amcred ontop txt25">Envoyer</span>
                    </Button.Content> 
                    <Button.Content hidden className="flexcenter">
                        <Icon className="amcred" size="big" name='envelope' style={{marginTop:'-7px'}} />
                    </Button.Content>
                </Button>
            </form>
            : (
                sendError 
                ? <div><div className="ui negative message tekotxt txt25">Il semble que l'envoie du message ait échoué, veuillez recommencer ou cliquez ici : <a href="mailto:contact@amcleblanc.fr" target="_blank" rel="noopener noreferrer">contact@amcleblanc.fr</a></div><Divider /><Button onClick={() => { this.setState({sendError:false, hasBeenSubmited:false}) }} className="centered" inverted>Recommencer <Icon name="redo"/></Button></div>
                : <div className="ui positive message tekotxt txt25">Votre message a été correctement envoyé, nous-y répondrons sous peu</div>
                
            )
        )
    }

    smallDevicesContactDisplay = () => <Segment attached="bottom">
        <Head 
            size="tiny"
            title={<span><span className="tekotxt titled anthracite noTitleSize">06 31 70 67 88</span> &nbsp;<Icon name="circle" size="mini" color="grey"/> <span className="tekotxt titled anthracite noTitleSize">03 22 37 28 56</span></span>}
            icon="phone"
            iconColor="grey"
        />
        <Head
            size="tiny"
            title={<span className="tekotxt titled anthracite noTitleSize">6 bis rue de Crémery, 80700 Liancourt-Fosse</span>}
            icon="envelope"
            iconColor="grey"
        />
    </Segment>

    render() {
        const {isSmallDevice} = Layout
        const iconsSize = isSmallDevice ? "large" : "huge"
        return(
            <div className="antbkg nomargin allheight">  
                <Divider horizontal className="txt30">
                    <h1 className="white tekotxt titled" style={{marginBottom:isSmallDevice ? '0em' : '2em'}}>
                        { !isSmallDevice && <Image src="coloredchaticon.png" alt="" size="tiny" centered />  }
                        Nous Contacter
                    </h1>
                </Divider> 

                <div className="ui center aligned padded grid ">
                    <div className={`${isSmallDevice ? 'sixteen' : 'ten'} wide rounded column`}>
                        <h1 className="white centered">
                            <Icon name="talk" size={iconsSize}/>
                        </h1>

                        <div className="ui horizontal divider"></div>
                        <div id="IcontactU" className="padded">
                            {this.maybeMessageSent()}                     
                        </div>
                    </div>
                    {
                        isSmallDevice
                        ? this.smallDevicesContactDisplay()
                        : <div className={`${isSmallDevice ? 'sixteen txtOneFiveAll' : 'five'} wide rounded column`}>
                            <h1 className="ui white" style={{marginTop : "8% !important"}}><i className="small phone icon"></i></h1>
                            <div className="ui horizontal divider"></div>
                            <h1 className="white tekotxt titled">06 31 70 67 88</h1>
                            <h1 className="white tekotxt titled">03 22 37 28 56</h1>
                            <div className="ui divider"></div>
                            <h1 className="ui white centered"><i className="small envelope icon"></i></h1>
                            <div className="ui horizontal marged divider"></div>
                            <h1 className="white"><span className="tekotxt titled">6 bis rue de Cremery,<div className="tekotxt titled">80700 Liancourt-Fosse</div></span></h1>
                            
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ContactUs