import React from 'react';
import Colors from '../constants/Colors'

export default class SideMenu extends React.Component {
    constructor() {
        super()
        this.state = {
            Views : [
                {section:"accueil"},
                {section:"entreprise"},
                {section:"metier", title:"métier"},
                {section:"secteur"},
                {section:"realisations", title:"réalisations"},
                {section:"garanties"},
                {section:"contact"},
                {section:"footer", title:"plan du site"},
            ]
        }
    }

    majFirstChar = (str) => {
        return str[0].toUpperCase() + str.slice(1)
    }

    render() {
        return(
            <div className="ontop flexcenter" style={{position:"absolute", right:'0px', height:'100%', flexDirection:"column"}}>
                {
                    this.state.Views.map((v) => {
                        let isActiveView = v.section === this.props.view
                        return <div key={Math.floor(Math.random() * 1000000)} style={{width:"100%",margin:'1em'}}><a href={'#'+v.section} data-tooltip={this.majFirstChar(v.title || v.section)} data-position="left" className="flexcenter"><div className="section-dot" style={{backgroundColor:isActiveView ? Colors.amcred : 'white'}}>&nbsp;</div></a></div>
                    })
                }
            </div>
        )
    }
}