import React, { Component } from 'react';
import { Divider, Icon, Image } from 'semantic-ui-react'
import Layout from '../constants/Layout'
import Head from './Head';

class Us extends Component {

    render() {
        const {isSmallDevice} = Layout

        return (
            <div style={{padding:'3%', flex: 1, display: 'flex'}} className="theciesegment">
               <div id="bio">

                        <Divider horizontal className={isSmallDevice ? "" : "txt30"}>
                            <h1 style={isSmallDevice ? {fontSize: '1.5em'} : undefined} className="tekotxt titled"><Icon name="building" size="small" className="centered tekotxt" /> L'entreprise</h1>
                        </Divider>    
                            
                        <Image className="ui slidein right small floated image" alt="" src="local_business.png"/>
                               
                                            
                        <div className={`left-aligned padded tekotxt ${isSmallDevice ? "txt15P" : "txt25"}`} style={isSmallDevice ? {fontSize: '.7em'} : undefined}>
                                <p>Situé au coeur de la Picardie, artisan passionné par son métier, Denis Leblanc à commencé à mettre en oeuvre son savoir-faire sur le terrain il y a maintenant 10 ans. Aujourd’hui, son équipe de professionnel et lui-même continuent à valoriser et prendre soin de vos biens immobiliers, tout en vous apportant toutes les garanties et la transparence que vous méritez.</p>
                                <p>Notre domaine d’expertise est varié, qu’il s’agisse de Maçonnerie (toutes oeuvres), Toitures, Menuiserie, travaux de Terrassement, d’Isolation, d’Aménagement (intérieur et extérieur) ou d’entretien, nous nous tenons prêt à répondre à vos besoins. <a className="clickable_tr shadowed anthracite" href="#realisations">Découvrez nos réalisations</a></p>
                                <p>A l’image de nos clients, chaque chantier est unique, c’est pourquoi dans le cadre d’un devis gratuit, nous nous rendons sur place afin de recueillir les détails techniques nécessaires à l’étude concrète de votre projet, nous vous conseillons et nous vous apportons des solutions qui s'adaptent à votre budget.
                                </p>
                                <p>Restez serein ! Le soin particulier que nous apportons aux détails nous permet de vous garantir des finitions parfaites, également, notre <a className="clickable_tr shadowed anthracite" href="#garanties">Garantie Décennal</a> couvre vos ouvrages pendant une durée de 10 ans.</p> 
                                <p style={{marginBottom:'0em !important'}}>Nous sommes à votre écoute, n'hésitez pas à <a className="clickable_tr shadowed anthracite" href="#contact">nous contacter</a></p>
                                
                                <div className="ui divider"></div>
                                {/* <h2 className="jello-horizontal centered">
                                    <Icon.Group size='large'>
                                        <Icon size='big' color="grey" name='file outline' />
                                        <Icon color='green' name='check' corner />
                                    </Icon.Group>
                                    <span className="ui centered redshadow tekotxt" style={isSmallDevice ? {fontSize: '.8em'} : undefined}> 
                                        Nos devis sont gratuits et sans engagement.
                                    </span>
                                    
                                </h2>   */}
                                <Head
                                    title="Nos devis sont gratuit"
                                    size="small"
                                    className="jello-horizontal tekotxtall"
                                    content={<span style={{fontSize: isSmallDevice ? '.8em' : '1.8em'}}>et sans engagement</span>}
                                    icon={<Icon.Group size='large'>
                                    <Icon size='big' color="grey" name='file outline' />
                                    <Icon color='green' name='check' corner />
                                </Icon.Group>}
                                />
                            
                        </div>
                    </div>
            </div>
        );
    }
}

export default Us; 