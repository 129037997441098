import React from 'react'

import { Button, Icon } from 'semantic-ui-react'

/**
 * @title Medias Slider
 * @dev Component responsible for displaying images or videos medias
 * ## Props :
 *  - Files : array of File type objects 
 */
function IconedButton(props) {
    const { title, color, style, className, iconPosition, icon, iconSize } = props
    return(
        <Button className={className} style={style} color={color} icon labelPosition={iconPosition || 'left'}>
            <Icon name={icon} size={iconSize} />
            {title}
        </Button>
    )
}

export default IconedButton