import React, { Component } from 'react'
import { Icon, Divider, Image, Header, Card, Grid, Button } from 'semantic-ui-react'

import Methods from '../constants/Methods'
import Colors from '../constants/Colors'
import Layout from '../constants/Layout'
import AppLogo from './AppLogo'
import LegalMentions from './LegalMentions'

/**
 * ##PROPS
 *  - recruitments (from app datas Config)
 *  - Log
 */
class Footer extends Component {
    state = {}

    logLegalMentions = () => {
        var { Log } = this.props    // App's "log" state setter
        Log({message:<LegalMentions/>, stay:true, closeButton:true, size: 'fullscreen', classAttribute:'tekotxtall'})
        return true
    }

    componentDidMount() {
        let search = window.location && window.location.search && window.location.search.slice(1).split("=")
        if(search && search.length && search[0] === 'legalmentions' && search[1] === 'true') {
            this.logLegalMentions()
            this.setState({displayLegalMentions: true})
        }
    }

    componentDidUpdate() {
        if (this.state.displayLegalMentions && !this.state.searchTreated) {
            this.setState({searchTreated: true})
            this.logLegalMentions()
        }
    }

    render() {
        var { Log, recruitments } = this.props    // App's "log" state setter
        const { isSmallDevice, isTablet } = Layout
        const hasOffers = (recruitments && recruitments.length)

        return(                     // txtOneFiveAll
            <div className={`padded allheight flexcenter tekotxtall ${isSmallDevice ? '' : 'txt20all'}`} >
                <div className="allheight light-padded" style={{border:'solid '+Colors.amcred, borderRadius:'10px'}}>

                    <div className="ui padded grid pageFooter">

                        {/* footer left menu */}
                        <div className="ui five wide column margedtop">
                            <div>
                                <div className="ui mid divider"></div>
                                <div><a href="#metier" className="clickable ui center aligned grey header"><h4>Métier</h4></a></div>

                                <div className="ui mid divider"></div>
                                <div><a href="#garanties" className="clickable ui center aligned grey header"><h4>Garanties</h4></a></div>

                                {/* <div className="ui mid divider"></div>
                                <div><a href="#fournisseurs" className="clickable ui center aligned grey header"><h4>Fournisseurs</h4></a></div> */}
                                <div className="ui mid divider"></div>    
                                <div><a href="#secteur" className="clickable ui center aligned grey header"><h4>Secteur</h4></a></div>
  
                                <div className="ui mid divider"></div>
                                <div><a href="#realisations" className="clickable ui center aligned grey header"><h4>Réalisations</h4></a></div>
                                <div className="ui mid divider"></div>    
                                <div><a href="#contact" className="clickable ui center aligned grey header"><h4>Nous Contacter</h4></a></div>
                                
                            </div>
                        </div>

                        {/* footer middle menu  */}
                        <div className="five wide clickable forced-line-height column">
                            <div className="ui mid divider"></div>
                            <a className="clickable ui center aligned grey small header" href="https://intranet.amcleblanc.fr/" target="_blank" rel="noopener noreferrer"><h4>Intranet</h4></a>
                            
                            <div className="ui mid divider"></div>      
                            <div className="clickable ui center aligned grey small header" onClick={() => { Log({
                                stay: hasOffers ? true : false,
                                title: hasOffers ? `${recruitments.length} offre${recruitments.length > 1 ? "s" : ""}` : 'Le recrutement est fermé pour le moment', 
                                message: <Header as="h3">{
                                    hasOffers
                                    ? <Grid className="scrollable">
                                        {
                                            recruitments.map((r) => <Grid.Column width={8}>
                                                <Card className="padded1 nomargin">
                                                    <Card.Content header={ <div className="txt25">{r.title}</div> } />
                                                    <Card.Content description={r.details} />
                                                    <Card.Content extra>
                                                    <div className="leftAligned">
                                                        <div><Icon size="tiny" name="calendar outline" /> ajouté le {Methods.formatedDate(new Date(r.addedOn), true)}</div>
                                                    </div>
                                                    </Card.Content>
                                                    <Card.Content extra className="nopadding nomargin centered">
                                                       
                                                        <Button><a href="mailto:amc.leblanc80@gmail.com">ENVOYEZ NOUS VOTRE CV</a></Button>   
                                                       
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>)
                                        }
                                    </Grid>
                                    : <div>
                                        <a href="#contact">CONTACTEZ-NOUS</a> 
                                        ou 
                                        <a href="mailto:amc.leblanc80@gmail.com">ENVOYEZ NOUS VOTRE CV</a>
                                    </div>
                                }</Header>, 
                                icon: hasOffers ? 'list alternate outline' : 'pause', 
                                iconColor:'teal', 
                                activityIndicator:false,
                                closeButton: hasOffers && true}); 
                            }}>
                                <h4>Recrutement</h4>
                            </div>

                            <div className="ui mid divider"></div>
                            <div className="clickable ui center aligned grey small header" onClick={this.logLegalMentions}><h4>Mentions Légales</h4></div>
                            
                            <div className="ui mid divider"></div>
                            <div className="clickable ui center aligned grey small header"><a href="https://workandup.fr" className="grey" target="_blank" rel="noopener noreferrer"><h4>Concepteur site web & application</h4></a></div>

                            <div className="ui mid divider"></div>    
                        </div>


                        {/* footer right column */}
                        <div className="ui six wide column" style={{backgroundColor:"white"}}>
                            <AppLogo size="small" />
                            <h5 className="paddedbottom centered anthracite" style={{fontWeight:'lighter'}}> &nbsp;&nbsp;SIREN : 51067398100013</h5>
                            <Divider />
                            <div className="flexcenter" style={isSmallDevice ? {flexDirection: "column", padding: '.1em'} : undefined}>
                                <Image src="rge.png" className="clickable" alt="site internet eco-artisan" size="small" style={{padding:"7%"}} href="http://www.eco-artisan.net" target="_blank" rel="noopener noreferrer"/>                               
                                <Image src="https://www.lemoniteur.fr/mediatheque/9/0/8/001402809.png" alt="site internet qualibat" className="clickable" style={isSmallDevice ? {} : {marginTop:"2%", padding:"3px", borderRadius:"7px", height:"75px", width:"75px", objectFit:"cover"}} href="https://www.qualibat.com/" target="_blank" rel="noopener noreferrer" />
                               
                            </div>
                            <Divider />

                            {/* contact */}
                            {
                                (isSmallDevice || isTablet)
                                ? ''
                                : <div className="ui grid">
                                    <div className="ui seven wide column">
                                        <Icon name="map marker alternate" style={{color:Colors.anthracite}} />
                                        <h4 className="anthracite grey"> <li>6 bis rue de Crémery,</li><li>80700 Liancourt-Fosse</li> </h4>
                                    </div>
                                    <div className="ui seven wide column">
                                        <Icon name="phone" style={{color:Colors.anthracite}} />
                                        <h4 className="anthracite grey"> <ul><li>06 31 70 67 88</li><li>03 22 37 28 56</li></ul> </h4>
                                    </div>
                                </div>
                            }
                            
                        </div>

                        <div className="ui bottom attached flexcenter clickable opac50 segment" style={{justifyContent:'bottom', marginBottom: 0}}>
                            <Image src="local_business.png" alt="entreprise 80700 roye" size={isSmallDevice ? "small" : "medium"}/>
                            <Image src="https://workandup-dev.s3.eu-west-3.amazonaws.com/IMAGES/villederoye.png" alt="ville de roye" size={isSmallDevice ? "tiny" : "small"} href="https://roye.eu" target="_blank" rel="noopener noreferrer" />       
                        </div>

                        {/* <!-- social medias + LBB + gallery --> */}
                        <div className="ui centered row">
                            <div className="five wide center aligned column">
                                <a className={`ui inverted clickable gray button ${isSmallDevice ? 'smallDeviceA' : ''}`} href="https://facebook.com/amcleblanc" target="_blank" rel="noopener noreferrer"><h3 className="anthracite">Suivez-nous sur Facebook<span><i><img className="ui tiny right-aligned margedbottom jello-horizontal centered image" src="fblog.png" alt="facebook AMC Leblanc" /></i></span></h3></a>
                            </div>

                            <div className="five wide center aligned column">
                                <a className={`ui inverted clickable gray button ${isSmallDevice ? 'smallDeviceA' : ''}`} href="https://www.labonnebrique.fr/vitrine-sup-internet-11412-amc-leblanc-couvreur-macon-renovation-isolation-menuisier-alu-pvc-liancourt-fosse-80700" target="_blank" rel="noopener noreferrer"><h3 className="anthracite">Nous sommes présent sur <b>Labonnebrique</b><span><i><img className="ui right-aligned margedbottom jello-horizontal centered image" src="lbb.png" alt="logo labonnebrique.fr" /></i></span></h3></a>
                            </div>

                            <div className="five wide center aligned column">
                            <a className={`ui inverted clickable gray button ${isSmallDevice ? 'smallDeviceA' : ''}`} href="https://www.facebook.com/pg/AMCleblanc/photos/" target="_blank" rel="noopener noreferrer"><h3>Gallery Photos<span><i><img className="ui tiny right-aligned margedbottom jello-horizontal image" src="galleryicon.png" alt="gallerie photos AMC Leblanc" /></i></span></h3></a>
                            </div>
                        </div>


                        
                    </div>
                    
                </div>
                
            </div>
        )
    }
}

export default Footer