import React, {Component} from 'react';
// roboto font
import 'typeface-roboto';
// app css
import './stylesheets/App.css';
// semantic ui css
import 'semantic-ui-css/semantic.min.css'
/* Segments */
// page header
import PageHeader from './components/PageHeader';
import Footer from './components/Footer';
import TheCie from './components/TheCie';
import ContactUs from './components/ContactUs';
import SectorMap from './components/SectorMap';
import RealisationsSlider from './components/RealisationsSlider';
import WorkTopics from './components/WorkTopics';
import Guarantees from './components/Guarantees'

import Log from './components/Log'
import AppLogo from './components/AppLogo'
import SideMenu from './components/SideMenu'
import {Header} from 'semantic-ui-react'


/**
 * @notice
 *  App uses ONE PAGE SCROLL: https://www.npmjs.com/package/one-page-scroll : a "section" tag is used as a wrapper
 *  /!\ DO NOT USE IDS SIMILAR TO SECTIONS NAMES ACROSS THE APP : results in issues with navigaton system /!\
 */
class App extends Component{
  constructor() {
    super()
    this.state = {
      loading:true,                   // loading state
      Realisations : [],              // Realisations list
      log : {},                       // log messages (in bound on distribution to other components)
      //degradedMode : false,         // Degraded Mode (when server issue / maintenance) (shutted)
      Config : undefined,             // App ConfigFile
      idInView : undefined,            // RealisationsSlider ID indicator
      currentView : 'accueil'                     // App current display view
    }
  }
 
  /**
   * @dev Initialize One Page Scroll from DOM sections tags and set inView and outView events
   */
  initOnePageScroll = () => {
    let _this = this
    /* ONE PAGE SCROLL */
    document.addEventListener('DOMContentLoaded', function() {
      
      // init one page scroll
      var sections = document.querySelectorAll('section')
      new window.onePageScroll({
          el: sections,
          loop: true,                       // have the page loop back to the top/bottom when the user navigates at up/down on the first/last page.
          pagination: true,                 // either show or hide the pagination. Toggle true for show, false for hide.  
      })

      function viewInHandler(e) {
          let currentView = e.target.getAttribute('name')
          console.log('vue : ', currentView)

          switch(currentView) {
            case 'realisations' :
              let openRealisation = window.locals && window.locals.openRealisation
             
                _this.setState(
                  openRealisation
                  ? {idInView:openRealisation}
                  : {currentView:currentView}
                )
              
              break;

            default :
            _this.setState({currentView})
      
              
          }
      }

      // function viewOutHandler(e) {
      //     let leavingView = e.target.getAttribute('name')
      //     //console.log('leavingView: %s', leavingView)
      //     // do something with leaving view
      // }

      // sections in view event
      for (var i in sections) {
          var section = sections[i];

          if (typeof section === 'object') {
              section.addEventListener('inview', viewInHandler)
              //section.addEventListener('outview', viewOutHandler)
          }
      }
    })
  }

  async componentDidMount() {
    const { Realisations } = this.state
    this.initOnePageScroll()
    if (!Realisations.length) {
      await this.getAndSetRAD()
    }
  }

  /**
   * @dev Get Remote App Datas and Set App state
   */
  getAndSetRAD = async() => {
    let remoteAppDatas = await this.fetchRemoteAppDatas()
    const {Config, Works, fetchError} = remoteAppDatas

    let visitorsMsg = await Promise.resolve(
      (Config && Config.visitorsMsg)
      ? {
        message: <div><AppLogo classAttribute={'centered bounce-in-top'} size={'small'}/><Header>{Config.visitorsMsg}</Header></div>,
        stay : true,
        closeButton:true
      } 
      : {}  // no visitors msg and no log 
    )
    !fetchError && this.setState({Realisations: Works, Config, log: visitorsMsg.message ? visitorsMsg : this.state.log, loading:false})
  }

  maybeDisplayVisitorsMsg = () => {
    const {Config} = this.state
    const {visitorsMsg} = Config

    return (
      visitorsMsg
      ? <Log
        message={visitorsMsg}  // Config.visitorsMsg.message
        stay={true}            // Config.visitorsMsg.stay
        title={'Bienvenue'}     // Config.visitorsMsg.title
      />
      : ''
    )
  }

 
  

  /**
   * @dev Fetch Realisations (works) to display on map
   */
  fetchRemoteAppDatas = () => {
    return new Promise(async(resolve) => {
        var ApiResponse = await fetch(
            //'/https://workandup-dev.s3.eu-west-3.amazonaws.com/WORK/KIUTJ_RHTEg4354y6U4352B546Nr5NV45',
            'https://amcleblancserver.herokuapp.com/api?credentials='+process.env.REACT_APP_WEBSITE_MAGIC+'::'+process.env.REACT_APP_WORK_SECRET+'&request=remoteApp', 
            {
                method:'GET',
                mode:'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )

        if (ApiResponse.status === 200) {
            //let datas = await ApiResponse.json(
                let responseJson = await ApiResponse.json()
                resolve(responseJson)
        } else {
            console.error('FETCH REMOTE APP DATA ERROR', ApiResponse)
            resolve({Works:[], Config:{}, fetchError: true})
        }
        
    })

}

  log = (logConfig) => {
    this.setState({log:logConfig})
  }

  maybeLogMsg = () => {
    const {log} = this.state
    var { message, title, icon, iconStyle, iconColor, activityIndicator, stay, closeButton, classAttribute, timedOutComponent, maxStay } = log
    maxStay = parseInt(maxStay)   // make sure we deal with a number type variable

    return(
      message || title 
      ? <Log 
          closed={typeof message === 'undefined'} 
          message={message}
          title={title}
          icon={icon}
          iconStyle={iconStyle}
          iconColor={iconColor}
          activityIndicator={activityIndicator}
          stay={stay}
          maxStay={maxStay}        
          timedOutComponent = {timedOutComponent}
          closeButton={closeButton}
          classAttribute={classAttribute || "centered"}
          logMethod={this.log.bind(this)}
        />
      : ''
    )
  }



  /** 
   * @dev Indicates RealisationsSlider component if a specific slide needs to be display
   * 
   */
  idInView = () => {
    return this.state.idInView
  }

  render() {
    console.log('app renders current view: ', this.state.currentView)
    const {Realisations, loading} = this.state
    return (
    
      <div className="App">
        {/* {this.maybeDegradedMode()} */}

        {this.maybeLogMsg()}
        {/* {this.maybeShowLoader()} */}
        <SideMenu view={this.state.currentView} />
        <section name="accueil"><PageHeader /></section>
        <section name="entreprise"><TheCie /></section>
        <section name="metier"><WorkTopics /></section>
        <section name="secteur"><SectorMap getAndSetRAD={this.getAndSetRAD.bind(this)} unloadedRAD={loading} Realisations={Realisations} /></section>
        <section name="realisations"><RealisationsSlider getAndSetRAD={this.getAndSetRAD.bind(this)} unloadedRAD={loading} idInView={this.idInView.bind(this)} Realisations={Realisations} Log={this.log.bind(this)}/></section>
        <section name="garanties" ><Guarantees /></section>
        <section name="contact" ><ContactUs /></section>
        <section name="footer"><Footer Log={this.log.bind(this)} recruitments={this.state.Config && this.state.Config.recruitments} /></section>
      </div>
    );
  }

}

export default App;






///// UNUSED METHODS / COMPONENTS /////
  // maybeShowLoader = () => {
 
  //   const { loading, Realisations } = this.state
  //   const {jumpTo} = Methods
  //   return(
  //     loading && !Realisations.length
  //     ? <Log 
  //       id="main-loader"
  //       closed={false} 
  //       message={''}
  //       activityIndicatorText={<span className="amcred txt25">Veuillez Patienter</span>}
  //       title={''}
  //       iconComponent={<AppLogo size="small"/>}
  //       activityIndicator={true}
  //       maxStay={10000} // indicate server failure / maintenance after 10scds
  //       timedOutComponent={<Message negative
  //         icon='server'
  //         header=''
  //         content={
  //           <div>
  //             <Header as="h4" style={{color:Colors.anthracite}}>OPÉRATION DE MAINTENANCE EN COURS</Header>
  //             <Segment size="big">certaines informations seront manquantes, telles que le détail de nos réalisations, le formulaire de contact et la carte de notre secteur</Segment>
  //             <TwoButtons 
  //               buttonOne={{title:'Visiter en mode dégradé',color:'standard', style:{backgroundColor:Colors.anthracite+' !important'}, clickHandler:() => this.setState({Realisations:[''], loading:false, degradedMode:true}) }}
  //               buttonTwo={{title:'Visiter notre Facebook', color:'facebook', clickHandler:() => jumpTo('https://facebook.com/amcleblanc')}}

  //             />
  //           </div>
  //         }
  //       />}
  //       closeButton={false}
  //       classAttribute={"centered"}
  //       logMethod={this.log.bind(this)}

        
  //     />
  //     : ''
  //   )
  // }

  
  // maybeDegradedMode = () => {
  //   const { degradedMode } = this.state;
  //   return(
  //     degradedMode && <Image floated className="ontop"><Label color='red'>
  //     mode dégradé
  //   </Label></Image>
      
  //   )
  // }
