
import React, {Component} from 'react';
import Constants from '../constants/Constants'
const { Topics } = Constants;
let _TOPICS_ = []

/**
 * ##PROPS
 *  - messengerClass
 *  - messengerStyle
 */
class AnimMessenger extends Component {

    constructor() {
        super();
        this.state = {
            topicIndex : 0
        }
    }

    /**
     * @dev Displayable Topics with prefix "d'" or "de" 
     */
    displayableTopics = () => {
        return new Promise((resolve) => {

            Topics.map(async(t, ti) => {
                const exitLen = Topics.length - 1
                _TOPICS_[ti] = await Promise.resolve(

                    ('aeiou'.indexOf(t[0].toLowerCase()) >= 0
                    ? ' d\' ' 
                    : ' de ') 
                    + t
                )
                if (ti === exitLen)
                    resolve(_TOPICS_)
            })
        })
    }
    displayableTopics = this.displayableTopics.bind(this)

    Messenger = (el) => {
        var {displayableTopics:topics, topicIndex} = this.state

        let $messenger = document.getElementById('messenger')
        let $content = topics && topics[topicIndex]
        
        if(typeof $content !== 'undefined') {
            //$messenger.classList.add('bounce-in-top')
            $messenger.innerHTML = $content
        }

        // change messenger msg every 4 scds
        setTimeout(() => {
            topics && topics.length && this.setState({topicIndex:(
                (topicIndex === topics.length - 1)
                ? 0
                : topicIndex + 1
            )})
        }, 2000)
           
        
        

    }

     componentDidMount() {
         if (!this.state.displayableTopics) {
            this.displayableTopics().then((topics) => {
                this.setState({displayableTopics:topics})
            })
         } else {
            this.Messenger();
         }
     }

     componentDidUpdate(){
        this.Messenger();
     }

    render() {
        return(
            <span id="messenger" style={this.props.messengerStyle} className={this.props.messengerClass || 'inline'}></span>
        )
    }

}

export default AnimMessenger;


    
   