import React, {Component} from 'react';
import {Loader, Divider, Icon, Image, Card, Button} from 'semantic-ui-react'
import Colors from '../constants/Colors';
import Slider from 'react-slick';
import MediasSlider from './MediasSlider'
import Methods from '../constants/Methods'
import IconedButton from './IconedButton'
import Layout from '../constants/Layout';

/**
 * ##PROPS
 *  - text
 *  - height
 */
class TextOverflow extends Component {

    state={
        checked: false,
        id: Date.now().toString()
    }

    componentDidMount() {
        console.log('--- TEXTOVERFLOW --- ')
        console.log(' - Height Limit: ', this.props.height)
        let $e = document.getElementById(this.state.id);
        const elemHeight = $e.clientHeight
        console.log(' - Text Height', elemHeight)
        if (elemHeight > this.props.height) {
            console.log('*OVERFLOW*')
            // resize
            this.setState({overflew: true})
        } else {
            console.log('*CONTAINED*')
            // ok
        }
    }
    render() {
        const {overflew, scrollText} = this.state
        return( // style={{height: this.props.height || '100%'}}
            <span>
                <p id={this.state.id} className={this.props.className || undefined} style={{...(this.props.style || {}), height: ((overflew && !scrollText) ? this.props.height : undefined), overflow: overflew ? 'hidden' : undefined}}>
                    {this.props.text}
                    
                </p>
                {
                    overflew
                    && <div className="white" style={{width: '100%', height: '1em', fontSize: '0.8em', padding: 0}} size="mini" onClick={() => this.setState({scrollText: !this.state.scrollText})}>{
                        scrollText ?
                        <Icon name="sort up" size="large" />
                        : <span className="white tekotxt">lire la suite</span>
                    }</div>
                }
            </span>
        )
    }
}

/**
 * @title RealisationsSlider
 */
class RealisationsSlider extends Component {

    constructor() {
        super()
        this.state = {
            loading : true,
            slideViews : undefined
        }

        this.formatedDate = Methods.formatedDate
    }

    // componentDidMount() {
    //     const {idInView} = this.props
    //     console.log('[mount] Realisation Slider idInView prop', idInView)
    // }


    fileElement = (f) => {
        const {isSmallDevice} = Layout
        return new Promise(async(fE) => {
            let alt_ = "exemple de réalisation AMC Leblanc"
            let type_ = await Promise.resolve(f.mimetype ? f.mimetype : (f.key ? f.key.toLowerCase() : 'image' /* default */))
            let Key = await Promise.resolve(Math.floor(Math.random() * 1000000))
            fE(
                type_.indexOf('image') >= 0 
                ? <div style={{height:isSmallDevice? "auto" : "70vh", width:"auto"}} key={Key}><img style={{maxHeight:"100%", width:"auto"}} className="ui centered huge image gallery-item" alt={alt_} src={f.location} /></div>
                : <div style={{height:isSmallDevice ? "40vh" : "70vh", width:"auto"}} key={Key}><video style={{maxHeight:"100%", width:"auto"}} className="gallery-item" controls src={f.location} alt={alt_}></video></div>
            )
        })
    }


    showMorePictures = (files) => {
        this.props.Log({
            title:'',
            message:<MediasSlider Files={files}/>,
            stay:true,
            closeButton:true

        })
        
    }


    initSlideshow = async() => {
        const { formatedDate } = this
        const { Realisations } = this.props
        const {isSmallDevice, isTablet, height} = Layout
        // prepare all views, then set slideViews state
        let allViews = await new Promise((res) => {
            let views = []
            if(!Realisations || !Realisations.length)
                res([])
            else {
                Realisations.map(async(r, ri) => {
                    
                    await new Promise(async(fView) => {
                        // skip if no files or realisation is not validated
                        if((!r.files) || !r.validated) fView([])
                        else {
                            const len = r.files.length
                            let f = await Promise.resolve(r.files[0])
                            //let Key = await Promise.resolve(Math.floor(Math.random() * 1000000))
                            let isMobile = (isTablet || isSmallDevice)

                            let element = await this.fileElement(f)
                            /* compute a grid with file element, then push to 'views' */
                            // let mobileCard = await Promise.resolve(
                            //     <Card key={r.id}>
                            //         {/* <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} /> */}
                            //         {element}
                            //         <Card.Content>
                            //         <Card.Header>{r.name}</Card.Header>
                            //         <Card.Meta>
                            //             <span className='date'>{formatedDate(new Date(r.date), true)}</span>
                            //         </Card.Meta>
                            //         <Card.Description>
                            //             {r.details}
                            //         </Card.Description>
                            //         </Card.Content>
                            //         <Card.Content extra>
                            //             {/* infos */}
                            //             <span style={isMobile ? {display : 'flex', flex: 1, alignItems: 'center'} : undefined}>
                            //             <div className={`vertical-margin left-aligned amcred tekotxt ${isMobile ? '1.5em' : 'txt25'}`}><Icon size={isMobile ? 'large' : 'big'} name="calendar alternate outline" />Réalisé le <span className="white">{formatedDate(new Date(r.date), true)}</span></div>
                            //             <div className={`left-aligned amcred tekotxt ${isMobile ? '1.5em' : 'txt25'}`}><Icon size={isMobile ? 'large' : 'big'} name="map marker alternate" />à <span className="white">{r.city}</span></div>
                            //             </span>
                            //             {((_this) => { 
                            //                 let hasS = ((len-1) > 1 ? 's ' : ' ')
                            //                 let bTitle = 'Voir ' + (len-1) + ' photo' + hasS + 'supplémentaire' + hasS
                            //                 return len > 1 
                            //                 ? <div onClick={() => _this.showMorePictures(r.files)}>
                            //                     {!isMobile && <Divider />}
                            //                     <IconedButton className="centered amcred inverted tiny button" icon="images" title={bTitle} iconSize="big" />
                            //                 </div>
                            //                 : '' 
                            //             })(this)}
                            //         </Card.Content>
                            //     </Card>
                            // )
                            let view_ = /* isMobile ? mobileCard : */await Promise.resolve(
                                <div key={r.id} className="ui allheight padded flexcenter grid">
                                    <div className={`ui ${isMobile ? 'sixteen' : 'five'} wide opac50 allheight column`}>

                                        {/* title */}
                                        <Divider inverted horizontal><h2 className="tekotxt" style={{textAlign:'justify', fontSize:isMobile ? '1.2em' : '25px'}}>{r.name}</h2></Divider>
                                        
                                        {/* details */}
                                        {/* <div className="bordered white tekotxt" style={{fontSize:isMobile ? '1.2em' : '25px'}}>{r.details}</div> */}
                                       <TextOverflow text={r.details} height={height * 0.2} className="bordered white tekotxt" style={{fontSize:'1.2em', margin: 0, padding: 0}}/>

                                        {/* infos */}
                                        <span style={isMobile ? {display : 'flex', flex: 1, alignItems: 'center'} : undefined}>
                                        <div className={`vertical-margin left-aligned amcred tekotxt ${isMobile ? '1.5em' : 'txt25'}`}><Icon size={isMobile ? 'large' : 'big'} name="calendar alternate outline" />Réalisé le <span className="white">{formatedDate(new Date(r.date), true)}</span></div>
                                        <div className={`left-aligned amcred tekotxt ${isMobile ? '1.5em' : 'txt25'}`}><Icon size={isMobile ? 'large' : 'big'} name="map marker alternate" />à <span className="white">{r.city}</span></div>
                                        </span>
                                        {((_this) => { 
                                            let hasS = ((len-1) > 1 ? 's ' : ' ')
                                            let bTitle = 'Voir ' + (len-1) + ' photo' + hasS + 'supplémentaire' + hasS
                                            return len > 1 
                                            ? <div onClick={() => _this.showMorePictures(r.files)}>
                                                {!isMobile && <Divider />}
                                                <IconedButton className="centered amcred inverted tiny button" icon="images" title={bTitle} iconSize={isSmallDevice ? "large" : "big"} />
                                            </div>
                                            : '' 
                                        })(this)}
                                    </div>
                                    {/* first media */}
                                    <div className={`ui ${isMobile ? 'sixteen' : 'eleven'} wide column`}>
                                        {element}
                                    </div>
                                </div>
                            )

                            fView(views.push(view_))    // this realisation file views SHOULD now be added
                        
                        }
                    })

                    if (ri === Realisations.length-1) 
                        res(views);
                })
            }
        })



        let slideSettings = await Promise.resolve({
            adaptiveHeight:false,
            autoplay:true,
            autoplaySpeed:5000,           // default 3000
            speed : 1000,                  // Slide/Fade animation speed
            centerPadding: '50px',        // default : 50
            dots : true,                  // default : false
            arrows : false,
            //appendArrows : document.querySelector('#slickarrows'),
            appendDots : dots => <span className="ui center aligned segment" style={{display:'flex', justifyContent:"center"}}>{dots}</span>,
            dotsClass : 'slick-dots',
            respondTo: 'min',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
        })

        
        this.setState({slideViews: allViews, slideSettings:slideSettings, loading: false})
       
    }


    async componentDidUpdate() {
        const { loading } = this.state
        const { unloadedRAD, getAndSetRAD }= this.props   // defined in App component: ( passed only if fetchError )

        if (unloadedRAD) {
            //try to load RAD
            setTimeout(() => {
                getAndSetRAD()
            }, 500)
        } else if (loading) {
            // init slideshow
            this.initSlideshow()
            
        } 
    }


    maybeShowLoader = () => {
        return(
            this.state.loading 
            ? <Loader size="huge" active inline inverted><span className="white tekotxt" style={{fontSize:'30px'}}>Chargement des réalisations en cours...</span></Loader>
            : ''
        )
    }

    maybeShowSlider = () => {
        const {loading, slideViews, slideSettings} = this.state;

        return(
            !loading && slideViews && slideSettings             /* TODO: use slider.goto if this.props.idInView()*/
            ? <div><div id="slickarrows"></div><Slider ref={slider => this.slider = slider} {...slideSettings}>  
                {slideViews}
            </Slider></div>
            : ''
        )
    }
    maybeGoToSlideIndex = () => {
        const { slideViews } = this.state
        var workId = this.props.idInView()

        if (workId) {
            var {slickGoTo} = this.slider
            // try to find a matching btw s.key (slide key === realisation slide id) and workId (app idInView state)
            new Promise((sliderSpotted) => {
                slideViews && slideViews.map((s, slideI) => {

                    if (parseInt(s.key) === workId)
                        sliderSpotted(slideI)
                })
            }).then((targetSliderIndex) => {
                slickGoTo(targetSliderIndex)
            })
        }
    }

    render() {
        // const {idInView} = this.props
        // var open = idInView()
        // console.log('open realisation', open)
        const isMobile = Layout.isSmallDevice || Layout.isTablet
        return(
             <div className="slidersegment padded tekotxt" style={{paddingBottom:isMobile ? '5em' : '7em'}}>
               <h1 className="light-padded white left-aligned">
                    <Divider horizontal className="white flexcenter txt30">
                        <h1 className="tekotxt titled" style={isMobile ? {fontSize: '1.6em'} : undefined}>
                            <Icon name="images" /> 
                            Nos Réalisations
                        </h1>
                        {
                            !isMobile 
                            && <Image size="tiny" src="logo_amc.png" alt="AMC Leblanc" style={{margin:'0% 0% 1% .5%'}} />
                        }
                    </Divider> 
                </h1>
                
               <div className={this.state.loading ? 'ui raised allheight margedbottom segment flexcenter' : 'ui raised allheight margedbottom segment'} style={{background:'transparent', border:'solid '+Colors.amcred, borderRadius:'10px'}} id="slider-main">
                    {this.maybeShowLoader()}
                    {this.maybeShowSlider()}
                    {this.maybeGoToSlideIndex()}
               </div>
                
           </div>
        )
    }
}

export default RealisationsSlider