import React, {Component} from 'react'
import { Divider, Icon, Image, Segment, Header } from 'semantic-ui-react'
import Head from '../components/Head'
import Layout from '../constants/Layout'

export default class Guarantees extends Component {

    constructor() {
        super();
        this.state = {
            Guarantees : [
                {
                    src:'ffb.png', 
                    alt:'informations Fédération Française du Batiment FFB',
                    floated:'left',
                    title:'NF DTU de la Fédération Française du Batiment', 
                    content: <p className="">
                        Nous maîtrisons et appliquons les règles de mise en oeuvre définies au <a href="https://www.ffbatiment.fr/federation-francaise-du-batiment/le-batiment-et-vous/travaux/les-nf-dtu.html" target="_blank" rel="noopener noreferrer">Document Technique Unifié de la Fédération Française du Batiment. </a><br />
                        Ces règles regroupent un ensemble de normes issues du <a href="https://www.bntec.fr/" target="_blank" rel="noopener noreferrer">BNTEC (Bureau de Normalisation)</a> précisent les conditions techniques et contractuelles pour la bonne exécution des ouvrages.
                    </p>
                },
                {
                    src:'rge.png', 
                    alt:'informations certification eco-artisan RGE', 
                    floated:'right',
                    title:'Reconnu Garant de l\'Environnement', 
                    content: <div><p className="">
                        Nous avons été reconnu garant de l'environnement et sommes qualifié RGE Eco-Artisan.<br />
                        La mention RGE est apposée sur un certain nombre de qualifications permettant d'identifier un professionnel compétent pour des travaux de rénovation énergétique et porteur des aides de l'Etat.<br />
                        <a href="https://www.eco-artisan.net/rge-reconnu-garant-environnement-explications" target="_blank" rel="noopener noreferrer">Plus d'informations sur les qualifications RGE </a>
                    </p>
                    
                    </div>
                },
                {
                    src:'decennale.png', 
                    alt:'informations sur la garantie décennale', 
                    floated:'left',
                    title:'Garanties Décennale', 
                    content: <div><p className="">
                        La garantie décennale est une responsabilité qui pèse sur les constructeurs à l'égard des maîtres d'ouvrage. <br />Elle couvre certains dommages pouvant affecter une construction dans sa solidité ou la rendant impropre à sa destination et ce, pendant une durée de 10 ans à compter de la réception des travaux.<br />
                        <a href="https://www.service-public.fr/particuliers/vosdroits/F2034" target="_blank" rel="noopener noreferrer">Plus d'informations sur la garantie décennale </a>
                    </p></div>
                }
            ]
        }
    }

    segment = (seg) => <div key={Math.floor(Math.random() * 10000)} className="tekotxtall padded txt20all">
        <h1>{seg.title}</h1>
        <Image src={seg.src} alt={seg.alt} className="marged" floated={seg.floated} size="small" />
        
            {seg.content}
    </div>
            
        
    

    mobileSegment = (seg) => <div key={Math.floor(Math.random() * 10000)} className="tekotxtall marged-top-two">
        <Head 
            iconInContent={true}
            size="small"
            title={seg.title}
            icon={<Image src={seg.src} alt={seg.alt} className="" floated="left" size="tiny" />}
            content={seg.content}
        />
    </div>

    render() {
        const {isSmallDevice, isTablet} = Layout
        return(
            <div>
                <Divider horizontal className="txt30">
                    <h1 className="tekotxt anthracite titled" style={isSmallDevice ? {lineHeight: '70%'} : undefined}><Icon name="certificate" size="small" className="centered tekotxt" /> Vos Garanties</h1>  
                </Divider>

                {
                    this.state.Guarantees.map((g) => {
                        return this[isSmallDevice ? "mobileSegment" : "segment"](g)
                    })
                }
            </div> 
        )
    }

}