import React from 'react'
import {Image} from 'semantic-ui-react'

/**
 * @dev PROPS :
 *  - size
 *  - classAttribute
 *  - floated
 */
export default class AppLogo extends React.Component {
    render() {
        return (
            <Image 
                src='logo_amc.png' 
                size={this.props.size} 
                alt="AMC Leblanc" 
                className={this.props.classAttribute} 
                floated={this.props.floated} 
                centered 
            />

        )
    }
}