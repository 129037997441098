import React from 'react';
import {Icon, Header} from 'semantic-ui-react'
/**
 * 
 * @param {object} props Head props 
 * ## Head Props
 *  - as (enum) : SUI Header 'as' prop (default: 'h2')
 *  - textAlign (enum) : SUI Header textAlign prop (default: 'center')
 *  - size (enum) : SUI Header 'size' prop (default: 'large')
 *  - style {object} : header component style
 *  - className (string) : additional className for Header component
 *  - title (enum or component) : header title
 *  - content (component) : header content 
 *  - contentStyle {object} : a style object for header content container
 *  - icon (component or enum) : icon name or custom icon component
 *  - iconColor (enum) : a color for the icon prop passed as enum
 */
const Head = (props) => 
    <Header as={props.as || "h2"} size={props.size || 'large'} className={`flexy flexCenter ${props.className || ''}`} style={props.style || undefined} textAlign={props.textAlign || 'center'}>
        {
            (props.icon && !props.iconInContent)
            && ( typeof props.icon === 'string'
                ? <Icon style={props.iconStyle} name={props.icon} color={props.iconColor || undefined}/>
                : props.icon
            )
        }
        <Header.Content style={props.contentStyle}>
            {props.iconInContent && props.icon}
            {props.title}
    
            <Header.Subheader className="margedLeft">
                {props.content}
            </Header.Subheader>
        </Header.Content>
    </Header>

export default Head;
