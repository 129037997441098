const tintColor = '#2f95dc';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',  
  noticeBackground: tintColor,
  noticeText: '#fff',
  anthracite : 'rgba(71,71,71,1)',
  amcred : 'rgba(167, 66, 48, 1)',
  white : '#ffffff'
};
