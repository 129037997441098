
var reformatTimeField = (tField) => {
    return tField < 10 ? '0' + tField : tField;
}



export default {

    /** Return Dates with format dd/mm/yyyy from new Date() method
    * @param da the date to format
    */
    formatedDate : (da, onlyDay) => {
        //let reformatTimeField = this.reformatTimeField
        let d = da.getDate();
        let m = da.getMonth() + 1; // months goes 0-11
        let y = da.getFullYear();
    
        var H = ' à ' + reformatTimeField(da.getHours()) + ' H ' + reformatTimeField(da.getMinutes());
        let M = reformatTimeField(m);
        let D = reformatTimeField(d);
        return (D + '/' + M + '/' + y + (onlyDay ? '' : H));
    },

    /*      N A V   H E L P    */

    // jump to a given location   
    jumpTo : (_location, openNew) => {                                  
        if (typeof openNew === 'undefined') {
    
            window.location.href = _location;   
        
        } else {
            window.open(_location)
        }
    }
}