import React from 'react'
import { Icon, Divider, Card, Image } from 'semantic-ui-react'
import Slider from 'react-slick';

import Layout from '../constants/Layout'

export default class WorkTopics extends React.Component {
    constructor() {
        super()
        this.state = {
            Topics : {
                "maconnerie" : {
                    title : 'La Maçonnerie',
                    
                    paragraphs : 
                        "La maçonnerie d’un bâtiment constitue et garantie sa structure ainsi que la qualité et la pérennité de son revêtement. Qu’il s’agisse de parpaing, de brique (traditionnel ou creuse), de béton cellulaire ou de béton banché, nous maîtrisons et mettons en application les règles de mise en oeuvre définies au <a href=\"https://www.ffbatiment.fr/federation-francaise-du-batiment/le-batiment-et-vous/travaux/les-nf-dtu.html\" target=\"_blank\">Document Technique Unifié de la Fédération Française du Batiment</a>. De plus, la qualité et la perennité de nos réalisations en maçonnerie vous sont garantie pendant 10 ans par notre <a onclick=\"_scrollTo(0, 'animMenu')\">garantie décennale</a>,,Nous nous adaptons aux constructions déjà présentes dans le cadre d'un <a onclick=\"loadTopic('renovation');\">projet de rénovation</a>."
                    ,
                    quote : "Chaque chantier est unique, nous nous adaptons et avons de l'expérience avec les conditions particulières liées au terrain, à l'altitude, aux accès difficiles, etc... ,,Chaque étude de projet l'est aussi, elles sont réalisées sur mesure et accompagnées<br /> de conseils variés et pertinents tout en restant adaptés à votre budget.",
                    picture:'maconnerie.png',
                    //picture : 'https://www.renovationettravaux.fr/wp-content/uploads/2013/12/parpeing-et-truel.jpg',
                    icon : '<i class="ui icon"><img class="ui big image" alt="macon roye" src="assets/images/arrow_wall.png"></i>',
                    subTopics : ["Construction", "Agrandissements", "Maisons Individuelles", "Garages", "Murets", "Devantures", "Enduits","Toutes réalisations en brique traditionnelle ou creuse, en parpaing, en béton cellulaire ou banché"]
                },
                "couverture" : {
                    title : 'La Couverture',
                    paragraphs : "Paragraph de la couverture...",
                    quote : 'Le toit au dessus de vos têtes doit être réaliser par de véritables professionnels',
                    picture:'toiture.png',
                    //picture : 'https://www.renovationettravaux.fr/wp-content/uploads/2015/05/toiture-en-tuile.jpg',
                    subTopics : ["Zinguerie", "Tuile", "Ardoise", "Tôle"],
                    icon : '<i class="ui icon"><img class="ui big image" alt="couvreur roye" src="assets/images/rooficon.png"></i>'
                },
                "renovation" : {
                    title : 'La Rénovation',
                    paragraphs : "Paragraph de la rénovation interieur / extérieur...",
                    quote : 'Nous valorisons et renovons votre habitat à l\'intérieur comme à l\'exterieur, découvrez nos réalisations',
                    picture:'renovation.png',
                    //picture : 'https://i.ibb.co/km5ZwP6/Capture-d-e-cran-2019-03-02-a-02-15-41.png',
                    subTopics : ["Parement", "Escalier", "Rejointoiement de briques", "Rénovation thermique de l'habitat", "Bardage", "Plaques de plâtre", "Carrelage && faïence", "Peinture", "Toutes types de rénovation intérieure ou extérieure"],
                    icon : '<i class="ui icon"><img class="ui big image" alt="rénovation roye" src="assets/images/renoicon.png"></i>'
                },
                "menuiserie" : {
                    title : 'La Menuiserie',
                    paragraphs : "Paragraph de la menuiserie...",
                    quote : 'Bois, aluminium ou PVC nous avons la solution qu\'il vous faut',
                    picture:'menuiserie.png',
                    //picture : 'https://i.ibb.co/QXNdKMm/menuiserie-roye-80700-AMC-Leblanc.png',
                    subTopics : ["Tous type de portes (garages, entrées, porte-fenêtres, services, portails, portillons)", "Tous type de fenêtres (murales, toits, baies, porte-fenêtres)"],
                    icon : '<i class="ui icon"><img class="ui big image" alt="menuisier roye" src="assets/images/windowicon.png"></i>'
                },
                "terrassement" : {
                    title : 'Le Terrassement',
                    paragraphs : "Paragraph du terrassement...",
                    quote : 'Un terrain bien préparé pour un chantier réussi ! ',
                    picture:'terrassement.png',
                    //picture : 'https://i.ibb.co/CmGsZXM/P21.jpg',
                    subTopics : ["Nivellement de terrain", "Assainissement", "Pose de regards", "Tranchée techniques", "Descente de garage"],
                    icon : '<i class="arrow alternate horizontal icon"></i>'
                },
                "isolation" : {
                    title : 'L\'Isolation',
                    paragraphs : "Paragraph de la menuiserie...",
                    quote : 'Bois, aluminium ou PVC nous avons la solution qu\'il vous faut',
                    picture:'isolation.png',
                    //picture : 'https://i.ibb.co/BPTKNWK/Capture-d-e-cran-2019-03-02-a-02-50-30.png',
                    subTopics : ["Isolation des combles", "Isolation par l'extérieur", "Isolation par l'intérieur", "Isolene soufflée", "Panneau polystyrène extrudé", "Laine de roche", "Laine de verre"],
                    icon : '<i class="home icon"></i>'
                },
                "amenagement" : {
                    title : 'L\'Aménagement',
                    paragraphs : "Paragraph de l'aménagement...",
                    quote : 'Faire de son chez-sois un endroit plus adapté à ses habitudes et plus agréable à vivre quotidiennement',
                    picture:'amenagement.png',
                    //picture : 'https://i.ibb.co/hYgDx59/Capture-d-e-cran-2019-03-02-a-03-01-18.png',
                    subTopics : ["Parement", "Création d'espaces", "Aménagement extérieur", "Aménagement intérieur"],
                    icon : '<i class="image outline icon"></i>'
                },
                "entretien" : {
                    title : 'L\'Entretien',
                    paragraphs : "Paragraph de l'entretien...",
                    quote : 'Entretenir son bien immobilier c\'est prolonger sa durée de vie et ça permet souvent de faire des économies importantes !',
                    picture:'entretien.png',
                    //picture : 'https://www.travaux.com/wp-content/uploads/2017/05/D%C3%A9moussage-de-toiture-Ooreka.jpg',
                    subTopics : ["Démoussage de toiture", "Entretien intérieur", "Entretien extérieur"],
                    icon : '<i class="wrench icon"></i>'
                },
            
            }
        }
    }

    componentDidMount() {
        const {isSmallDevice} = Layout

        /* IF small device slider, Replace arrows to append them on top of slider */
        if (isSmallDevice && !this.state.arrowAppened) {
            window.onload = () => {
                let $arrows = document.querySelector('.slider-arrows')          // div to appened arrows to
                let $currentArrows = document.querySelectorAll('.slick-arrow')
                
                for (var arrow of $currentArrows) {
                    
                    ((_arrow) => {
                        let clone = arrow.cloneNode(true)
                        let isNext = clone.innerText.indexOf('Next') >= 0
                        clone.innerHTML = `<i class="${isNext ? 'arrow right' : 'arrow left'} icon marged"></i>`
                        clone.addEventListener('click', (e) => {
                            _arrow.click()
                        })
                        $arrows.append(clone)
                        _arrow.setAttribute('style', 'display: none !important')

                    })(arrow)

                    
                }

                this.setState({arrowAppened: true})
            } 
        }

    }


    // maybeDisplayTopicsMenu = () => {
    //     const {Topics} = this.state

    //     for (var key in Topics) {
    //         let topic = Topics[key]
    //         return topic.title
    //     }
    // }

    topicCard = (topic, key) => {
        const {isSmallDevice, isTablet} = Layout
        let wide = isSmallDevice ? "sixteen" : (isTablet ? "five" : "four")
        return (
            <div key={Math.floor(Math.random() * 100000)} className={`ui ${wide} wide column`}>
                <Card className="centered">
               
                    <Image 
                        alt={`${key} à roye`}
                        src={topic.picture} 
                        wrapped ui={false} 
                        verticalAlign='middle' 
                    />
                
                    <Card.Content>
                    <Card.Header>{topic.title}</Card.Header>
                    <Card.Meta>
                        <span className='date'>Nous réalisons</span>
                    </Card.Meta>
                    <Card.Description>
                        {topic.subTopics.join(', ')}
                    </Card.Description>
                    </Card.Content>
                    {/* <Card.Content extra>
                    <a>
                        <Icon name='plus' />
                        Découvrir
                    </a>
                    </Card.Content> */}
                </Card>
            </div>
        )
    }

    smallDeviceSlider = () => {
        const {Topics} = this.state

        let slideSettings = {
            adaptiveHeight:false,
            autoplay:true,
            autoplaySpeed:3500,           // default 3000
            speed : 700,                  // Slide/Fade animation speed
            centerPadding: '50px',        // default : 50
            dots : false,                  // default : false
            arrows : true,
            appendArrows : document.querySelector('.slider-arrows'),
            //appendDots : dots => <span className="ui center aligned segment" style={{display:'flex', justifyContent:"center"}}>{dots}</span>,
            dotsClass : 'slick-dats left-justif',
            respondTo: 'min',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
        }

        return <Slider id="topicsSlider" {...slideSettings}>{
            Object.keys(Topics).map((key) => {
                return this.topicCard(Topics[key], key)
                //console.log('topic', topic)
            })
        }</Slider>
        

    }

    render() {
        const {Topics} = this.state
        const {isSmallDevice, isTablet} = Layout

        return(
            <div className="tekotxtall">
                <Divider horizontal className="txt30">
                    <h1 className="titled anthracite"><Icon name="react" size="small" className="centered tekotxt" /> Notre Métier</h1>
                </Divider> 
                
                {
                    !isSmallDevice
                    ? <div className="ui center aligned grid">
                    {
                        Object.keys(Topics).map((key) => {
                            return this.topicCard(Topics[key], key)
                            //console.log('topic', topic)
                        })
                    }
                    </div>
                    : <div>
                        <div className="slider-arrows" height="3em" width="100%" style={{display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center'}}></div>
                        {
                            this.smallDeviceSlider()
                        }
                    </div>
                }
            </div>
        )
    }
}