import React, {Component} from 'react'
import { Divider, Button, Loader } from 'semantic-ui-react'
import L from 'leaflet'
import Methods from '../constants/Methods'
import Colors from '../constants/Colors'


class SectorMap extends Component {
    constructor() {
        super();
        
        this.state = {
            loaded : false
        }

        this.formatedDate = Methods.formatedDate
        
    }


    componentDidUpdate() {
        const { loaded } = this.state           // set by this.initMap
        const { Realisations, unloadedRAD, getAndSetRAD } = this.props;    // passed by App component
        if (!unloadedRAD && !loaded) { 
            this.initMap()  // sets 'loaded' to true
        } else if (loaded) {
            (async() => {
                console.log('map loaded with '+Realisations.length+' realisations')
                let $map = document.getElementById('sector_map')
                let $class = $map.getAttribute('class')
                $map.setAttribute('class', ($class+' allheight'))
                
            })()  
        } else if (unloadedRAD) {
            // try to get RAD and set App state again
            setTimeout(() => {
                getAndSetRAD()
            }, 500)
        }

        // 

        //
            
    }

    
    /**
    * @dev no params version
    */
    sectorMapIcons = () => {  
        let {formatedDate} = this   
        var amc = [{ ciePlace: true, src:'amc_pointer.png', coord:'auto', popup:'<div class="ui horizontal divider">AMC Leblanc</div>80700 Roye (somme)', click:true}]
        var WPS = [];
        return new Promise(async(resolve) => {
            let workplacesIcons = await Promise.resolve(this.props.Realisations);
            //alert(workplacesIcons.length)
            new Promise((mapped) => {
                workplacesIcons.map(async($e) => {
                    if ($e && $e.validated) {
                        let source = $e.files[0].location
                        let medias = '<img class="ui centered small image" alt="" src="'+source+'" />'//await htmlFromFiles($e.files)
                        let moreButton = '<div class="ui centered antbkg amcred circular tiny button" onclick="window.locals = {openRealisation:'+$e.id+'}, window.location.href = \'/#realisations\'">Voir la réalisation &nbsp;<i class="eye icon"></i></div>'
                        let popUp = 
                            '<div class="clickable_tr tekotxtall openedwork" style="height:100%; width:100%">' +  // before react component event : onclick="openWorkplace(\''+ $e.id +'\')" (replaced by class elements event 'openedwork')
                                '<div class="ui horizontal divider">' +
                                    $e.name +
                                '</div>' + 
                                medias + 
                                '<div class="ui center aligned tekotxt segment">' +
                                    (
                                        $e.city 
                                        ? (
                                            $e.date ? 'Réalisé le '+ formatedDate(new Date($e.date), true)+'<br />à '+$e.city : 'à '+$e.city
                                        ) : (
                                            'Réalisé le ' + formatedDate(new Date($e.date), true)
                                        )
                                    ) + 
                                    '<br />' +
                                    moreButton +
                                '</div>' +
                            '</div>'
                           
                        
                        WPS.push({
                            src: 'workicon.png', 
                            coord:[$e.latitude, $e.longitude], 
                            popup: popUp,//'<div class="clickable_tr" onclick="openWorkplace(\''+ $e.id +'\')"><div class="ui horizontal divider">'+$e.name+'</div>' + medias + ($e.city ? ($e.date ? 'Réalisé le '+formatedDate(new Date($e.date), true)+'<br />à '+$e.city : ($e.city||'pas d\'infos sur le lieu')) : ('Réalisé le ' + formatedDate(new Date($e.date), true))) + '</div>',
                            click:true,  // desactive popup auto open
                            id: $e.id
                        })
                    }
                })
        
                setTimeout(() => {
                    mapped(WPS)
                })
            }).then((workplaceMapped) => {
                let concatenated = amc.concat(workplaceMapped);
                resolve(concatenated);
            })
        })

    }

    initMap = async() => { 
        var MapOpt = await new Promise(async(resolve) => {
            var ICONS_ = await this.sectorMapIcons();
            var OPTIONS_ = await Promise.resolve({
                mapId : 'sector_map',
                //min_height:'45em',
                //min_width:'100%',
                //max_height:'45em',
                //max_width:'100%',
                position:'center',
                latitude : 49.6969875,  
                longitude : 2.7905661,
                zoom : 9,
                /* CIRCLE SECTOR */
                circle : {
                    coord : 'auto',
                    radius : 60000,
                    color: 'rgb(0, 204, 153)',//'rgb(29, 206, 138)',
                    fillColor: 'mediumturquoise',/*'#f03'*/ //'lightgreen',
                },    // or radius number 
                /* POLYGONAL SECTOR */
                polygon : {
                    coord : false
                }, 
                /* ICONS */
                icons : ICONS_, 
                attribution : ''
            })

            resolve(OPTIONS_)
        })

        if (!MapOpt) return false;  // reject if not options receives else
        else {                      // Set Map :

            // Set Map view
            var rdy_map = await new Promise((res, rej) => {
                document.querySelector('#'+MapOpt.mapId)
                
                res(L.map(MapOpt.mapId).setView([MapOpt.latitude,MapOpt.longitude],MapOpt.zoom));
            
            })
        
        
            // Set Map Tile Layer
            await new Promise((res, rej) => {
                // deprecated tileLayer :
                // L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
                //     attribution: MapOpt.attribution || '',//'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                //     maxZoom: 18,
                //     id: 'mapbox.streets',
                //     accessToken: 'pk.eyJ1IjoibWF0c29saWRpdHkiLCJhIjoiY2pyeDJ5b2s3MGhqZzRhcGtjeWljYmk5YiJ9.l28YshTF6cAFTjV0BAiaHg'
                // })

                // nice streets and buildings views tileLayer :
                L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors 2'
                }).addTo(rdy_map);
        
                res(true)
            })
        
            // Add/Set Map Markers
            await new Promise(async(res, rej) => {
                if (!MapOpt.markers) res(false)
                else {
                    MapOpt.markers.map((ma, mai) => {    // ma (coord array) = [lat, lon]
                        // marker
                        L.marker(ma,{
                            draggable: true
                        }).addTo(rdy_map)//.on('click', initWorkSlideshow(MapOpt.id);
        
                        if (mai === MapOpt.markers.length-1)
                            res(true)

                        return true
                    })
                }
            })
        
            // Set Map Icons
            await new Promise(async(res, rej) => {
                // Icon Object (with options)
                var LeafIcon = L.Icon.extend({
                    options: {
                        shadowUrl: '',
                        iconSize:     MapOpt.iconsSize || [50, 70],
                        shadowSize:   [50, 75],
                        iconAnchor:   [22, 94],
                        shadowAnchor: [4, 62],
                        popupAnchor:  [-3, -76]
                    }
                });
                // Add Map Circle, Polygon, Icons
                await new Promise((resolve, reject) => {
                    if (MapOpt.icons) {
                        var Markers = []
                        // Add icons
                        MapOpt.icons.map(async(e, i) => {
                            await new Promise((acc) => {
                                //////
                                if (e.coord.length) {
                                    var $icon = MapOpt.icons[i]
                                    let $popup = $icon.popup
                                    var _icon = new LeafIcon({iconUrl: $icon.src})

                                    // icon click event
                                    var I = L.marker($icon.coord === 'auto' ? [MapOpt.latitude, MapOpt.longitude] : $icon.coord, {icon:_icon}).addTo(rdy_map)//.on('click', () => { alert('do something here') })
                                    // if 'click' key is true : desactive popup auto open
                                    I.bindPopup($icon.click); 
                                    
                                    if ($popup) {
                                        
                                        if ($icon.click) {
                                            Markers.push({marker: I, popup : $popup});
                                            
        
                                        } else I.bindPopup($popup).openPopup() 
                                        
                                    }
                                    acc();
        
                                } else acc();
                            })
                            
                        })
                        if (Markers.length) {
                            // bind all markers popups
                            Markers.map((e) => {
                                
                                e.marker.bindPopup(e.popup)//.openPopup(); 
                                return true
                            })
                        }
                    }
                    if(MapOpt.polygon.coords && MapOpt.polygon.coords.length >= 3) {
                        L.polygon(MapOpt.polygon.coords).addTo(rdy_map)
                    }
        
                    if(MapOpt.circle) {  
                        L.circle(
                            (MapOpt.circle.coord === 'auto' || MapOpt.circle.coord === true) ? 
                            [MapOpt.latitude, MapOpt.longitude] : 
                            MapOpt.circle.coord, 
                            {
                                color: MapOpt.circle.color,
                                fillColor: MapOpt.circle.fillColor,
                                fillOpacity: 0.5,
                                radius: MapOpt.circle.radius || 500 // default circle radius : 500
                            }
                        ).addTo(rdy_map);
                    }
                    
                    resolve(true)
                    
                })
        
                res(true)   
            })

            console.log('%s loaded', MapOpt.mapId)            
            
            setTimeout(async() => {
                rdy_map.invalidateSize()
            
            })
           
            // display map
            this.setState({loaded:true})
            return true;
        }
    
        
    }

    centerMapHandler = () => {
        // new element
        let newMapDiv = document.createElement('div')
        newMapDiv.id = 'sector_map';

        // replace
        document.getElementById('sector_map').replaceWith(newMapDiv)

        // re Init
        this.setState({loaded: false})
    }

    showLoader = () => <div className="allheight flexcenter">
        <Loader 
            size="huge" 
            active 
            inline 
            inverted
        >
            <span className="white tekotxt" style={{fontSize:'30px'}}>Chargement de la carte en cours...</span>
        </Loader>
    </div>
    
    
    
    render() {
        const { loaded } = this.state
        
        return(
            
            <div className="sgmradius mapsegment tekotxt">
                

                <h1 className="light-padded white left-aligned">
                    <Divider horizontal className="txt30 white">
                        <h1 className="tekotxt titled"><i className="ui icon"><img alt="secteur amc maçonnerie toiture rénovation 80700 Roye" className="ui image" src="mapmarkericon.png" /></i> Secteur D'Activité</h1>
                    </Divider> 
                </h1>
  
                <div id="map_wrapper" className="ui center aligned allheight padded grid" style={{paddingBottom:'7em'}}>
                    
                    <div className="fourteen wide centered allheight column" style={{paddingBottom:'5em'}}>
                        
                        <div className="opac50 light-padding" style={{borderRadius:'10px 10px 0px 0px'}}>
                            <Button style={{color:Colors.amcred}} data-tooltip="Centrer la vue" circular size="big" icon="thumbtack" onClick={this.centerMapHandler}/>
                            <Button style={{color:Colors.anthracite}} circular size="big" icon="info"/>
                        </div>
                    
                        <div id="sector_map"></div>
                        {
                            !loaded 
                            && this.showLoader()
                        }
                        
                    </div>
                    
                </div>

                
            </div>
            
        )
    }
}   

export default SectorMap; // style={{height:'80%', width:'80%'}}