import React, { Component } from 'react';
// import Constants from '../constants/Constants'
import Colors from '../constants/Colors';
import Layout from '../constants/Layout'


class Menu extends Component {
   

    render() { 
        const {isSmallDevice} = Layout        
        return(
            <div className="ui opac50 segment">  
                <div className={`ui center aligned whiteshadowed grid ${isSmallDevice ? "smallScreenMenu" : ""}`}>
                    <div className="clickable five wide column">
                        <h2><a href="#entreprise" style={{color:Colors.white}}><i className="building outline amcred icon"></i><br />Notre Entreprise</a></h2>
                    </div>
                    <div className="clickable five wide column">
                        <h2><a href="#metier" style={{color:Colors.white}}><i className="react amcred icon"></i><br />Notre Métier</a></h2>
                    </div>
                    <div className="clickable five wide column">
                        <h2><a href="#realisations" style={{color:Colors.white}}><i className="images outline amcred icon"></i><br />Nos Réalisations</a></h2>
                    </div>
                 
                    <div className="clickable five wide column">
                        <h2><a href="#garanties" style={{color:Colors.white}}><i className="object ungroup outline amcred icon"></i><br />Vos Garanties</a></h2>
                    </div>

                    {/* <div className="clickable five wide column">
                        <h2><a href="#fournisseurs" style={{color:Colors.white}}><i className="dolly amcred icon"></i><br />Nos Fournisseurs</a></h2>
                    </div> */}

                    <div className="clickable five wide column">
                        <h2><a href="#secteur" style={{color:Colors.white}}><i className="object map marker alternate amcred icon"></i><br />Notre Secteur</a></h2>
                    </div>
                
                </div>
            </div>  
        )
    }

}

export default Menu
