
export default {
    Topics : [
        "Maçonnerie",
        "Rénovation",
        "Couverture",
        "Isolation",
        "Menuiserie",
        "Terrassement",
        "Assainissement",
        "Peinture"
    ], 
    
    scrollTo : (pos) => {
        if(window.location.hash !== '#/') {
            window.scrollTo(0, pos) ;
        }
    },

    newWindow : (link) => {
        window.open(link)
    },

    timeFormat : (t) => {
        return t < 10 ? '0'+t : t;
    },

    jumpTo : (link) => {
        if (link)
        window.location.href = link;
    },

   

    
}

